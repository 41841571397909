import Link from "next/link";
import {
  API_KEYS,
  ABOUT_URL,
  SOCIAL_MEDIA_URLS,
  COMPANY_URLS,
  URL_COMPONENTS_NEW as URL_COMPONENTS,
} from "../__constants__";
import LinkedInIcon from "./vectors/LinkedInIcon";
import TwitterIcon from "./vectors/TwitterIcon";
import FacebookIcon from "./vectors/FacebookIcon";

export default function Footer() {
  return (
    <footer className="w-full bg-primary-blue py-8">
      <div className="mx-auto px-8">
        <div className="flex xs:flex-row flex-col sm:[&_h4]:text-[0.875rem] [&_h4]:text-[0.675rem] sm:[&_a]:text-[1.2rem] [&_a]:text-[1rem] justify-center">
          <div className="flex flex-row basis-1/2 w-full xs:justify-center justify-start">
            <div className="basis-1/2 w-full flex xs:justify-center justify-start px-2">
              <div>
                <h4 className="leading-[1.43] tracking-[1px] text-mint-green font-semibold uppercase">Find An Adviser</h4>
                <ul className="text-sm list-none p-0">
                  <li className="mb-4">
                    <Link href={`/${URL_COMPONENTS.FA_MA_CANONICAL}`}>
                      <a className="text-white text-[1.2rem] no-underline" href={`/${URL_COMPONENTS.FA_MA_CANONICAL}`}>
                        Financial &amp; Mortgage Advisers
                      </a>
                    </Link>
                  </li>
                  <li className="mb-4">
                    <Link href={`/${URL_COMPONENTS.SOLICITOR}`}>
                      <a className="text-white text-[1.2rem] no-underline" href={`/${URL_COMPONENTS.SOLICITOR}`}>Legal Advisers</a>
                    </Link>
                  </li>
                  <li>
                    <Link href={`/${URL_COMPONENTS.ACCOUNTANT}`}>
                      <a className="text-white text-[1.2rem] no-underline" href={`/${URL_COMPONENTS.ACCOUNTANT}`}>Accountants</a>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="basis-1/2 w-full flex xs:justify-center px-2">
              <div>
                <h4 className="leading-[1.43] tracking-[1px] text-mint-green font-semibold uppercase">Company</h4>
                <ul className="text-sm list-none p-0">
                  <li className="mb-4">
                    <Link href={`${ABOUT_URL}about/what-we-do`}>
                      <a href={`${ABOUT_URL}about/what-we-do`} className="text-white text-[1.2rem] no-underline">
                        About Us
                      </a>
                    </Link>
                  </li>
                  <li className="mb-4">
                    <Link href={`${ABOUT_URL}blog`}>
                      <a href={`${ABOUT_URL}blog`} className="text-white text-[1.2rem] no-underline">
                        Tips &amp; Guides
                      </a>
                    </Link>
                  </li>
                  <li className="mb-4">
                    <Link href={COMPANY_URLS.VF_CONTACT_PAGE_URL}>
                      <a href={COMPANY_URLS.VF_CONTACT_PAGE_URL} className="text-white text-[1.2rem] no-underline">
                        Contact
                      </a>
                    </Link>
                  </li>
                  <li>
                    <Link href={`${ABOUT_URL}about/careers`}>
                      <a href={`${ABOUT_URL}about/careers`} className="text-white text-[1.2rem] no-underline">
                        Careers
                      </a>
                    </Link>
                  </li>
                </ul>
              </div>
              
            </div>
          </div>
          
          <div className="flex flex-row basis-1/2 w-full justify-center">
            <div className="basis-1/2 w-full flex xs:justify-center justify-start px-2">
              <div>
                <h4 className="leading-[1.43] tracking-[1px] text-mint-green font-semibold uppercase">Legal & Cookies</h4>
                <ul className="text-sm list-none p-0">
                  <li className="mb-4">
                    <Link href="/terms-and-conditions">
                      <a  href="/terms-and-conditions" className="text-white text-[1.2rem] no-underline">Terms &amp; Conditions</a>
                    </Link>
                  </li>
                  <li>
                    <Link href="/privacy-policy">
                      <a href="/privacy-policy" className="text-white text-[1.2rem] no-underline">Privacy Policy</a>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="basis-1/2 w-full flex justify-start xs:justify-center px-2">
              <div>
                <h4 className="leading-[1.43] tracking-[1px] text-mint-green font-semibold uppercase">VouchedFor Professional</h4>
                <ul className="text-sm list-none p-0">
                  <li className="mb-4">
                    <Link href={`${API_KEYS.ACCOUNTS_URL}/register`}>
                      <a href={`${API_KEYS.ACCOUNTS_URL}/register`} className="text-white text-[1.2rem] no-underline">
                        Sign up
                      </a>
                    </Link>
                  </li>
                  <li>
                    <Link href={`${API_KEYS.ACCOUNTS_URL}/login`}>
                      <a href={`${API_KEYS.ACCOUNTS_URL}/login`} className="text-white text-[1.2rem] no-underline">
                        Log in
                      </a>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="pt-8 border-t border-gray-200">
          <div className="flex items-center justify-center flex-col">
            <span className="text-white">&copy; <Link href="https://www.vouchedfor.co.uk"><a className="no-underline text-white">VouchedFor 2024</a></Link></span>
            <div className="flex mt-4 space-x-4 sm:justify-center">
              <Link href={SOCIAL_MEDIA_URLS.LINKEDIN} aria-label="Visit VouchedFor on LinkedIn">
                <a href={SOCIAL_MEDIA_URLS.LINKEDIN} aria-label="Visit VouchedFor on LinkedIn" alt="VouchedFor on LinkedIn" className="w-9 h-9 rounded-full bg-white flex justify-center items-center hover:bg-secondary-blue">
                  <LinkedInIcon />
                </a>
              </Link>

              <Link href={SOCIAL_MEDIA_URLS.TWITTER} aria-label="Visit VouchedFor on X (formerly Twitter)">
                <a href={SOCIAL_MEDIA_URLS.TWITTER} aria-label="Visit VouchedFor on X (formerly Twitter)" alt="VouchedFor on X (formerly Twitter)"  className="w-9 h-9 rounded-full bg-white flex justify-center items-center hover:bg-secondary-blue">
                  <TwitterIcon />
                </a>
              </Link>
              
              <Link href={SOCIAL_MEDIA_URLS.FACEBOOK} aria-label="Visit VouchedFor on Facebook">
                <a href={SOCIAL_MEDIA_URLS.FACEBOOK} aria-label="Visit VouchedFor on Facebook" alt="VouchedFor on Facebook" className="w-9 h-9 rounded-full bg-white flex justify-center items-center hover:bg-secondary-blue">
                  <FacebookIcon />
                </a>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}
