import React, { useEffect, useRef, useState } from 'react';
import { useDebouncedCallback } from "use-debounce";
import axios from "axios";
import { API_KEYS, CONTACT } from "../../__constants__";
import CustomOptionLabel from "./CustomOptionLabel";
import * as analytics from "../../analytics";
import AutocompleteInput from '../Inputs/AutocompleteInput';
import { useRouter } from "next/router";
import useClickOutside from '../../hooks/useClickOutside';

export const ApiNameSearch = props => {
    const router = useRouter();
    const [inputValue, setInputValue] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [open, setOpen] = useState(false);

    const autoCompleteRef = useRef(null)
    useClickOutside(autoCompleteRef, () => setOpen(false))

    const handleOptionSelected = (option) => {
        if (option) {
            props.setLoading(true);
            // Navigate to the selected option (covers hover over option and hit enter, besides option click)
            router.push("/" + (option.metadata.profile_url ? option.metadata.profile_url : '404'));
        }
    }

    const handleInputClick = () => {
        // Send event to Google Analytics when the autocomplete field is clicked
        analytics.track('clicked_name_search', {
            category: 'name_search',
        });

        if (suggestions.length) {
            setOpen(true)
        }
    }

    const debouncedApiCall = useDebouncedCallback(
        async (inputValue) => {
            if (inputValue.length >= 3) {
                try {
                    const response = await axios.get(`${API_KEYS.API_URL}/v2/public/search/name?query=${inputValue}`);
                    setSuggestions(response.data.data);
                } catch (error) {
                    setSuggestions([]);
                }
            } else {
                // Clear suggestions if inputValue is less than 3 characters
                setSuggestions([]);
            }
        },
        // Debounce delay (milliseconds) - to avoid saturating API
        250
    )

    useEffect(() => {
        if (suggestions.length > 0) {
            setOpen(true);
            const firstOption = "/" + (suggestions[0].metadata.profile_url ? suggestions[0].metadata.profile_url : '404');
            props.onFirstOptionChange(firstOption);
        } else {
            props.onFirstOptionChange(null);
        }
    }, [suggestions])

    useEffect(() => {
        debouncedApiCall(inputValue);
    }, [inputValue])

    return (
        <AutocompleteInput
            id={props.id || 'search-for-adviser-by-name'}
            ref={autoCompleteRef}
            className={props.className}
            placeholder='Adviser or firm'
            disabled={props.loading}
            data-test='name-search-select'
            onClick={handleInputClick}
            onOptionSelected={handleOptionSelected}
            onChange={e => setInputValue(e.target.value)}
            value={inputValue}
            options={suggestions}
            renderOption={(option) => (<CustomOptionLabel option={option} onClick={() => handleOptionSelected(option)} />)}
            open={open}
            noOptionsText={`Call ${CONTACT.ACCOUNTS_TEAM_NUMBER} to get help finding the right adviser or firm`}
        />
    );
};

export default ApiNameSearch;
