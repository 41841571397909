import {
    HIDE_NOTIFICATION_MESSAGE,
    SHOW_NOTIFICATION_MESSAGE,
} from './types';

export const showNotification = () => {
    return {
        type: SHOW_NOTIFICATION_MESSAGE,
    };
};

export const resetNotification = () => {
    return {
        type: HIDE_NOTIFICATION_MESSAGE,
    };
};
