export default function Button({ onClick, text, className, disabled = false, dataTest = null, bgColour = 'bg-secondary-blue hover:bg-faded-blue' }) {
    return (
        <button
            data-test={dataTest}
            disabled={disabled}
            onClick={onClick}
            type="submit"
            className={`${className} relative rounded-full border-0 transition duration-100 ${disabled ? 'bg-disabled-blue cursor-not-allowed' : `${bgColour} cursor-pointer`} p-4 py-1.5 text-left shadow-sm focus:outline-none sm:text-sm sm:leading-6`}
        >
            <span className="flex items-center">
                <span className={`${className} block truncate text-base text-white tracking-wider font-bold font-['Nunito'] uppercase`}>{text}</span>
            </span>
        </button>
    )
}
