import { forwardRef, useRef, useState } from "react";
import TextInput from "./TextInput";
import Link from "next/link";
import useClickOutside from "../../hooks/useClickOutside";

const AutocompleteInput = forwardRef(({ placeholder, onChange, value, renderOption, className, noOptionsText = '', id, open = false, options = [], onOptionSelected, disabled = false, ...rest }, ref) => {
    const [highlightedOptionIndex, setHighlightedOptionIndex] = useState(-1)
    useClickOutside(ref, () => setHighlightedOptionIndex(-1))
    const ulScrollbarRef = useRef(null)
    const highlightedLiRef = useRef(null)
    
    const handleMouseEnter = (event, optionIndex) => {
        const { currentTarget } = event

        if (optionIndex === highlightedOptionIndex) {
            return
        }

        currentTarget.classList.add('bg-secondary-blue')
        currentTarget.classList.add('[&_*]:!text-white')
    }

    const handleMouseLeave = (event, optionIndex) => {
        const { currentTarget } = event

        if (optionIndex === highlightedOptionIndex) {
            return
        }

        currentTarget.classList.remove('bg-secondary-blue')
        currentTarget.classList.remove('[&_*]:!text-white')
    }

    const handleArrowDownPress = () => {
        ulScrollbarRef?.current.scrollTo(0, highlightedLiRef?.current?.offsetTop)
        setHighlightedOptionIndex(++highlightedOptionIndex)
    }

    const handleArrowUpPress = () => {
        if (highlightedLiRef?.current) {
            const elementHeightPx = 64
            ulScrollbarRef?.current.scrollTo(0, highlightedLiRef?.current?.offsetTop - (elementHeightPx * 2))
        }

        setHighlightedOptionIndex(--highlightedOptionIndex)
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && highlightedOptionIndex !== -1) {
            e.preventDefault()
            const selectedOption = options[highlightedOptionIndex]
            onOptionSelected(selectedOption)
        }

        if (e.key === 'ArrowDown' && highlightedOptionIndex < options.length - 1) {
            e.preventDefault()
            handleArrowDownPress()
            return
        }

        if (e.key === 'ArrowUp' && highlightedOptionIndex > 0) {
            e.preventDefault()
            handleArrowUpPress()
            return
        }
    }
    
    return (
        <div className="w-full relative" ref={ref} data-test='search-for-adviser-by-name-input-container'>
            <TextInput
                id={id || 'search-for-adviser-by-name'}
                className={`${open && !disabled ? 'rounded-t-2xl rounded-b-none' : ''} ${className}`}
                placeholder={placeholder}
                disabled={disabled}
                onChange={onChange}
                onKeyDown={handleKeyDown}
                autoComplete={false}
                value={value}
                {...rest}
            />

            {open && !disabled && 
                <ul ref={ulScrollbarRef} className="absolute z-10 mt-0 pl-0 max-h-56 w-full overflow-auto rounded-b-2xl bg-white py-0 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm" tabIndex="-1" role="listbox" aria-labelledby="listbox-label" aria-activedescendant="listbox-option-3">
                    {options.length > 0 &&
                        options.map((option, index) => {
                            const profileLink = "/" + (option.metadata.profile_url ? option.metadata.profile_url : '404')

                            return (
                                <Link href={profileLink} data-test={`name-search-option-profile-link-${profileLink}`}>
                                    <li
                                        ref={highlightedOptionIndex === index ? highlightedLiRef : null}
                                        key={`select-options:${option.metadata.profile_url}`}
                                        onMouseEnter={e => handleMouseEnter(e, index)}
                                        onMouseLeave={e => handleMouseLeave(e, index)}
                                        className={`relative cursor-pointer select-none py-2 pr-3 pl-3 list-none ${index === highlightedOptionIndex ? 'bg-secondary-blue [&_*]:!text-white' : 'text-gray-900'}`}
                                        id={`select-options:${option.metadata.profile_url}`}
                                        role="option"
                                    >
                                            {renderOption(option)}
                                    </li>
                                </Link>
                            )
                        })
                    }
                    {!options.length &&
                        <li key={`select-options:no-options`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} className="relative cursor-default select-none py-1 pr-3 pl-3 text-gray-900 list-none" id="listbox-option-0" role="option">
                            <p>{noOptionsText}</p>
                        </li>
                    }
                </ul>
            }
        </div>
    )
})

export default AutocompleteInput
