import React from 'react'
import {Grow, Snackbar, Typography} from "@material-ui/core";
import PropTypes from "prop-types";
import {makeStyles} from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import {vfColours} from "../Theme";

const PopUpNotification = ({open, handleClose, message, severity}) => {
    const classes = useStyles();

    return (
        <Snackbar
            autoHideDuration={5000}
            open={open}
            onClose={handleClose}
            TransitionComponent={Grow}
        >
            <Alert
                onClose={handleClose}
                severity={severity}
                className={
                    severity === 'warning'
                        ? classes.popUpNotificationWarning
                        : classes.popUpNotificationSuccess
                }
                icon={<></>}
            >
                <Typography>
                    {message}
                </Typography>
            </Alert>
        </Snackbar>
    )
}
const useStyles = makeStyles(theme => ({
    popUpNotificationWarning: {
        background: theme.palette.error.light,


    },
    popUpNotificationSuccess: {
        background: vfColours.grassGreen,
    },
}));
const propTypes = {
    open: PropTypes.bool.isRequired,
    message: PropTypes.string.isRequired,
    handleClose: PropTypes.func.isRequired,
    severity: PropTypes.string.isRequired,
}

PopUpNotification.propTypes = propTypes
export default PopUpNotification;
