import { useEffect, useRef, useState } from "react"
import useClickOutside from "../../hooks/useClickOutside"

export default function Select({ onSelection, defaultSelection, options, renderOption, className, id = 'default' }) {
    const [isDrowndownShown, setIsDropdownShown] = useState(false)
    const [selectedOption, setSelectedOption] = useState(defaultSelection || options[0] || { label: '', value: '' })
    const dropdownRef = useRef(null)

    useEffect(() => {
        if (defaultSelection) {
            setSelectedOption(defaultSelection)
        }
    }, [defaultSelection])

    useClickOutside(dropdownRef, () => setIsDropdownShown(false))

    const handleSelectClick = () => {
        setIsDropdownShown(!isDrowndownShown)
    }

    const handleOptionClick = (option) => {
        setSelectedOption(() => {
            onSelection(option)
            setIsDropdownShown(false)
            return option
        })
    }

    const handleOptionHover = (event) => {
        const { currentTarget } = event
        currentTarget.classList.toggle('bg-secondary-blue')
        currentTarget.classList.toggle('[&>*]:!text-white')
    }

    return (
        <div className="w-full" data-test={`select-box-container-${id}`}>
            <div className={`relative ${className || ''}`}>
                <button onClick={handleSelectClick} type="button" className={`relative w-full cursor-default rounded-full border-0 bg-white py-2 pl-3 pr-10 text-left shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-secondary-blue sm:text-sm sm:leading-6`} aria-haspopup="listbox" aria-expanded="true" aria-labelledby="listbox-label">
                    <span className="flex items-center">
                        <span className="ml-1 block truncate text-base text-primary-blue">{selectedOption.label}</span>
                    </span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                        <svg className="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fillRule="evenodd" d="M10 3a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02L10 4.852 7.3 7.76a.75.75 0 01-1.1-1.02l3.25-3.5A.75.75 0 0110 3zm-3.76 9.2a.75.75 0 011.06.04l2.7 2.908 2.7-2.908a.75.75 0 111.1 1.02l-3.25 3.5a.75.75 0 01-1.1 0l-3.25-3.5a.75.75 0 01.04-1.06z" clipRule="evenodd" />
                        </svg>
                    </span>
                </button>

                {isDrowndownShown && 
                    <ul ref={dropdownRef} className="absolute z-10 mt-1 pl-0 max-h-56 w-full overflow-auto rounded-2xl bg-white py-0 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm" tabIndex="-1" role="listbox" aria-labelledby="listbox-label" aria-activedescendant="listbox-option-3">
                        {options.map((option) => {
                            const isSelected = option.value === selectedOption.value

                            return (
                                <li key={`select-options:${option.value}-${option.label}`} onClick={() => handleOptionClick(option)} onMouseEnter={handleOptionHover} onMouseLeave={handleOptionHover} className="relative cursor-default select-none py-2 pr-3 pl-3 text-gray-900 list-none" id="listbox-option-0" role="option">
                                    {renderOption(option, isSelected)}
                                </li>
                            )
                        })}
                    </ul>
                }
            </div>
        </div>
    )
}