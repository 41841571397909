import Link from "next/link";
import { getCorrectUrlType } from "../../utils/urlHelpers";

export const cityLinks = [
  {
    link: "/central-england/cities",
    label: "Central England",
  },
  {
    link: "/london",
    label: "London",
  },
  {
    link: "/north-england/cities",
    label: "North England",
  },
  {
    link: "/scotland-and-northern-ireland/cities",
    label: "Scotland & Northern Ireland",
  },
  {
    link: "/east-england/cities",
    label: "East England",
  },
  {
    link: "/south-east-england/cities",
    label: "South East England",
  },
  {
    link: "/south-west-england/cities",
    label: "South West England",
  },
  {
    link: "/wales/cities",
    label: "Wales",
  },
  {
    link: "/isle-of-wight/cities",
    label: "Isle of Wight",
  },
];

const NavItem = ({ label, role, disableDropdown, positionDropdownToRight = false }) => {
  return (
    <div className="self-center relative group [&_p_a]:hover:text-secondary-blue" data-test={`nav-item-container-${role}`}>
      <p
        aria-haspopup="true"
        className="text-[1.1rem] font-normal leading-6 m-0 dropdown z-50"
      >
        <Link href={`/${role}`}>
          <a
            data-test={`nav-item-link-${role}`}
            href={`/${role}`}
            className="block py-3 px-4 z-50 relative text-[1rem] max-[1128px]:text-[0.9rem] max-[1004px]:text-[0.8rem] font-bold tracking-[0.1rem] uppercase text-primary-blue hover:text-secondary-blue no-underline"
          >
            {label}
          </a>
        </Link>
      </p>
      {!disableDropdown &&
        <div className={`dropdown-menu absolute hidden h-auto group-hover:block top-8 w-80 sm:right-0 ${positionDropdownToRight ? 'right-0 -mr-4' : 'md:left-0'}`} data-test={`nav-item-dropdown-menu-${role}`}>
          <ul className="top-0 bg-white list-none px-0 py-2 shadow rounded-lg">
            {cityLinks.map((item) => {
              const href = `/${getCorrectUrlType(role)}${item.link}`
              
              return (
                <Link href={href} key={`nav-item:${item.label}`}>
                  <li className="py-3 px-6 hover:bg-tertiary-blue cursor-pointer [&_a]:text-primary-blue [&_a]:hover:!text-secondary-blue">
                      <a className="font-bold text-[1rem] no-underline" data-test={`nav-item-dropdown-${role}-${item.link}`} href={href}>{item.label}</a>
                  </li>
                </Link>
              )
            })}
          </ul>
        </div>
      }
    </div>
  );
};

export default NavItem;
