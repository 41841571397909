import Link from "next/link";
import { API_KEYS, URL_COMPONENTS_NEW } from "../../__constants__";
import { useRef, useState } from "react";
import useClickOutside from "../../hooks/useClickOutside";

export default function MobileMenu() {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)
    const mobileMenuRef = useRef(null)
    useClickOutside(mobileMenuRef, () => setIsMobileMenuOpen(false))

    return (
        <>
            <div className="absolute inset-y-0 right-2 flex items-center sm:hidden">
                <button type="button" onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)} className="z-[999] relative inline-flex bg-transparent focus:outline-none border-none cursor-pointer items-center justify-center rounded-md p-2 text-primary-blue" aria-controls="mobile-menu" aria-expanded="false">
                <span className="absolute"></span>
                <span className="sr-only">Open main menu</span>

                {!isMobileMenuOpen &&
                    <svg className="block h-8 w-8" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                    </svg>
                }
                
                {isMobileMenuOpen &&
                    <svg className="block h-8 w-8" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                }
                </button>
            </div>
        
            {isMobileMenuOpen &&
                <div ref={mobileMenuRef} className="sm:hidden right-0 absolute top-10 bg-white p-0 rounded-b-lg" id="mobile-menu">
                    <div className="space-y-1 px-0 pb-3 pt-2 uppercase">
                        <Link href={`/${URL_COMPONENTS_NEW.FA_MA_CANONICAL}`}>
                            <a href={`/${URL_COMPONENTS_NEW.FA_MA_CANONICAL}`} className="block rounded-none px-8 py-3 text-base font-bold no-underline text-primary-blue hover:bg-secondary-blue hover:text-white" aria-current="page">Financial & Mortgage Advisers</a>
                        </Link>
                        
                        <Link href={`/${URL_COMPONENTS_NEW.SOLICITOR}`}>
                            <a href={`/${URL_COMPONENTS_NEW.SOLICITOR}`} className="block rounded-none px-8 py-3 text-base font-bold no-underline text-primary-blue hover:bg-secondary-blue hover:text-white">Solicitors</a>
                        </Link>
                        
                        <Link href={`/${URL_COMPONENTS_NEW.ACCOUNTANT}`}>
                            <a href={`/${URL_COMPONENTS_NEW.ACCOUNTANT}`} className="block rounded-none px-8 py-3 text-base font-bold no-underline text-primary-blue hover:bg-secondary-blue hover:text-white">Accountants</a>
                        </Link>

                        <p className="block rounded-none px-8 py-2 pt-4 pb-0 text-xs font-bold no-underline text-secondary-blue">
                            VouchedFor Professional?
                        </p>
                        
                        <Link href={`${API_KEYS.ACCOUNTS_URL}/register`}>
                            <a href={`${API_KEYS.ACCOUNTS_URL}/register`} className="block rounded-none px-8 py-3 text-base font-bold no-underline text-secondary-blue hover:bg-secondary-blue hover:text-white">Register</a>
                        </Link>

                        <Link href={`${API_KEYS.ACCOUNTS_URL}/login`}>
                            <a href={`${API_KEYS.ACCOUNTS_URL}/login`} className="block rounded-none px-8 py-3 text-base font-bold no-underline text-secondary-blue hover:bg-secondary-blue hover:text-white">Login</a>
                        </Link>
                    </div>
                </div>
            }
        </>
    )
}