
export const getCorrectUrlType = (type) => {
    if (type == 'solicitors') return 'solicitor'
    return type
}

export function isProfessionalTypeInUrlCorrect(urlComponents, type) {
    return Object.values(urlComponents).includes(type)
}

export function redirect() {
    return {
        redirect: {
            destination: '/404',
            permanent: false,
        },
    }
}

export const getNewUrlType = (urlPath) => {
    if (urlPath == '/solicitor') return '/solicitors'
    return urlPath
}