import React from 'react';
import VfStar from "../vectors/VfStar";
import { vfColours } from "../../Theme";
import { defaultPictureAvatar } from "../../assets/images";
import Apartment from "@material-ui/icons/Apartment";
import AvatarCircle from '../AvatarCircle';

export const CustomOptionLabel = ({ option, onClick }) => {
    const profileLink = "/" + (option.metadata.profile_url ? option.metadata.profile_url : '404');

    return (
        <div className='py-1 flex-nowrap items-center flex box-border' data-test={`name-search-option-${profileLink}`} onClick={onClick}>
            <div className='flex pr-3 items-center justify-center' data-test="name-search-option-avatar">
                {
                    option.result_type === "adviser" ? (
                        option.metadata.avatar ?
                            <AvatarCircle src={option.metadata.avatar} />
                            :
                            <AvatarCircle src={defaultPictureAvatar} />
                    ) : (
                        option.metadata.avatar ?
                            <AvatarCircle src={option.metadata.avatar} />
                            :
                            <Apartment />
                    )
                }
            </div>
            <div className='grow-0 basis-1/2' data-test="name-search-option-text">
                <p className='text-left text-sm leading-6 m-0'>
                    {option.result_text}
                </p>
                {
                    (option.result_type === "adviser" && option.metadata.firm_name) &&
                    <p className='text-[.65rem] m-0 text-left'>
                        {option.metadata.firm_name}
                    </p>
                }
            </div>
            {
                option.metadata.surveys_count ?
                    <>
                        <div className='grow-0 basis-1/6' data-test="name-search-option-surveys-score">
                            <p className='text-xs text-right m-0'>
                                {option.metadata.surveys_score} <VfStar fill={vfColours.mintGreen}/>
                            </p>
                        </div>
                        <div className='grow-0 basis-1/4' data-test="name-search-option-surveys-count">
                            <p className='text-xs text-right m-0'>
                                {option.metadata.surveys_count} {option.metadata.surveys_count > 1 ? "reviews" : "review"}
                            </p>
                        </div>
                    </>
                    :
                    <div className='grow-0 basis-5/12' data-test="name-search-option-no-surveys">
                        <p className='text-xs text-right m-0'>
                            No reviews yet.
                        </p>
                    </div>
            }
        </div>
    );
};

export default CustomOptionLabel;