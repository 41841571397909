import { ASSET_URL, S3_URL } from '../__constants__';

export const defaultPictureAvatar = ASSET_URL + 'default_profile_picture.png';
export const logoWhite = ASSET_URL + 'vf_logo_white.png';
export const logoWithVerticalsBlueOnWhite = ASSET_URL + 'vf2023/logo.png';
export const topRatedFirmBadge = ASSET_URL + 'vf2023/top_rated_firm.png';
export const topRatedFirmBadgeHorizontal = ASSET_URL + 'vf2023/top_rated_firm_horizontal.png';
export const tipsAndGuidesLookFor = ASSET_URL + 'tips-and-guides2.jpg';
export const tipsAndGuidesExpect = ASSET_URL + 'tips-and-guides1.jpg';
export const lockIcon = ASSET_URL + 'lockIcon.svg';
export const errorIcon = ASSET_URL + 'cancel.svg';
export const checksIphone = ASSET_URL + 'home/newshadowelliot.png';
export const peopleIcon = ASSET_URL + 'home/peopleicon.png';
export const starsIcon = ASSET_URL + 'home/stars.png';
export const guideBannerLarge = ASSET_URL + 'guides/guide_image_v2.png';
export const faGuideBannerMobile2020 = ASSET_URL + 'guides/fa_guide_banner_2020_mobile.jpg';
export const faGuideBannerLarge2020 = ASSET_URL + 'guides/fa_guide_banner_2020_large.jpg';
export const accountantsGuideBannerLarge = ASSET_URL + 'guides/accountants_guide_image_2020.png';
export const accountantsGuideLogo = ASSET_URL + 'guides/accountants_guide_mobile_logos_2020.png';
export const accountantsGuideBannerMobile = ASSET_URL + 'guides/accountants_guide_mobile_bg.jpg';
export const guideBannerBg = ASSET_URL + 'guides/mini_banner_bg.jpg';
export const guideLogo = ASSET_URL + 'guides/mini_banner_guide.png';
export const ftAndTelegraphImg = ASSET_URL + 'guides/mini_banner_logos-left.png';
export const telegraphImg = ASSET_URL + 'accounts/mini_banner_logos-left-v2.png';
export const timesAndEsImg = ASSET_URL + 'guides/mini_banner_logos-right.png';
export const consumerDutyAllianceImage = ASSET_URL + 'CDA-LogoOnly.svg';

export const dmIcon = ASSET_URL + 'vf2023/home/Daily_Mail_Logo_Navy.png';
export const guardianIcon = ASSET_URL + 'vf2023/home/The_Guardian_Logo_Navy.png';
export const independentIcon = ASSET_URL + 'vf2023/home/Independent_Logo_Navy.png';
export const itvIcon = ASSET_URL + 'vf2023/home/ITV_Logo_Navy.png';
export const sundaytimesIcon = ASSET_URL + 'vf2023/home/The_Sunday_Times_Navy.png';
export const timIcon = ASSET_URL + 'home/tim-w.png';
export const homepageHeroBackground = ASSET_URL + "vf2023/home/VF_Website_Header_Image_Template.png";
export const professionalPageBackground = ASSET_URL + 'home/beach_background.jpg';
export const pavLogo = ASSET_URL + 'home/pav-logo-transparent-small.png';
export const feeReportBackground = ASSET_URL + 'fee-report-background.png';
export const feeBadge = ASSET_URL + 'fee-badge.jpg';
export const awardIcon = ASSET_URL + 'vf2023/VF_Trophy_Icon_Circle_Mint_Green.png';
export const ukMap = ASSET_URL + 'vf2023/town/uk-map.png';
export const testimonialImage = ASSET_URL + 'vf2023/home/testimonial-image.png';
export const firmpageHeroBackground = '/firmHero.png'
export const profileImages = {
    JOSEPH_OSHEA: S3_URL + 'f7ac1be4bc3498d0d93674098a90665874489557.png',
    SIAN_MACINNES: S3_URL + '8485a531c9d895344883cbb4264eb33fa1c94382.jpeg',
    STEWART_TWINDLE: S3_URL + '16e716e675a28fc14d5650526933480b7a138c81.png',
    FLORA_BARTON: S3_URL + '71c442dedbe8b14c78a8875d24229572fe3e0165.png',
    ELLIOTT_SWATTON: S3_URL + '027cd89d1e39a0797028613b0e31439788ea80cc.jpeg',
};
