import { useState } from "react";
import * as analytics from "../analytics";
import { URL_COMPONENTS_NEW as URL_COMPONENTS } from "../__constants__";
import { withRouter } from "next/router";
import { getCorrectUrlType } from "../utils/urlHelpers";
import ApiNameSearch from "./NameSearch/ApiNameSearch";
import TextInput from "./Inputs/TextInput";
import Select from "./Inputs/Select";
import LoadingSpinner from "./vectors/LoadingSpinner";
import SelectOption from "./Inputs/SelectOption";
import Button from "./Inputs/Button";

export function HeroImageSection({ router }) {
  const [location, setLocation] = useState('')
  const [personType, setPersonType] = useState(URL_COMPONENTS.FA_MA_CANONICAL)
  const [locationSearch, setLocationSearch] = useState(true)
  const [redirect, setRedirect] = useState(false)
  const [firstSearchByNameOption, setFirstSearchByNameOption] = useState(null)
  const [isRedirectingToProfile, setIsRedirectingToProfile] = useState(false)

  if (redirect) {
    router.push(redirect)
  }

  const handleSwitch = (searchType) => {
    analytics.track("switched_search_type", {
      category: `switch_to_${searchType}_search`,
    })

    setLocationSearch(searchType === 'location')
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    if (locationSearch) {
      analytics.track("Search", {
        category: "Homepage",
        label: "Submit",
      })

      return setRedirect(`/${location ? getCorrectUrlType(personType) : personType}/${location}`)
    }

    if (firstSearchByNameOption) {
      setIsRedirectingToProfile(() => {
        setRedirect(firstSearchByNameOption)
        return true
      })
    }
  }

  const handleFirstSearchByNameOptionChange = (firstSearchByNameOption) => {
    setFirstSearchByNameOption(firstSearchByNameOption)
  }

  const handleSetLoading = (loading) => {
    setIsRedirectingToProfile(loading)
  }

  const selectOptions = [
    { label: 'Financial and Mortgage Adviser', value: URL_COMPONENTS.FA_MA_CANONICAL },
    { label: 'Legal Adviser', value: URL_COMPONENTS.SOLICITOR },
    { label: 'Accountant', value: URL_COMPONENTS.ACCOUNTANT }
  ]

  const defaultSelection = selectOptions.find(opt => opt.value === personType) || selectOptions[0]

  return (
    <form className="flex mt-0 p-8 sm:px-10 px-2" onSubmit={handleSubmit}>
      <div className="w-full flex flex-wrap box-border z-10">
        <div className="flex grow-0 flex-col justify-center md:max-w-[62%] w-full">
          <div className="w-full flex flex-wrap box-border gap-2">
            <div className="grow-0 w-full basis-full m-0 box-border md:text-left text-center">
              <h1 className="text-mint-green font-semibold sm:text-[3.75rem] leading-[1.2] m-0 xs:text-[3rem]">
                Home to the UK's
                <br />most trusted advisers
              </h1>
            </div>
            <div className="grow-0 w-full basis-full m-0 box-border md:text-left text-center">
              <h2 className="text-white text-[1.3rem] font-normal">
                Search thousands of experts, <br />
                all vetted, reviewed and monitored
              </h2>
            </div>
            <div className="grow-0 w-full basis-full m-0 box-border">
              {locationSearch && (
                  <div className="flex sm:flex-row flex-col gap-3 sm:text-left justify-center md:justify-start text-center px-2 xs:px-[5.5rem] sm:px-56 md:px-0">
                    <div className="basis-1/3 box-border min-w-[225px] md:min-w-0">
                      <label className="text-mint-green text-[0.7rem] font-bold uppercase">
                        Search by town / city / postcode
                      </label>
                      <TextInput
                        className='[&_*]:text-base'
                        placeholder='Town, city, postcode'
                        onChange={(e) => setLocation(e.target.value)}
                        value={location}
                        id='town_city_postcode-input'
                      />
                    </div>
                    <div className="basis-1/3 box-border">
                      <label className="text-mint-green text-[0.7rem] font-bold uppercase">
                        Select advice type
                      </label>
                      <div className="flex gap-4 xs:gap-3 xs:flex-row flex-col [&>div]:min-w-[292.7px]">
                        <Select
                          className="[&>button]:py-3 [&>button]:font-semibold"
                          defaultSelection={defaultSelection}
                          onSelection={selection => setPersonType(selection.value)}
                          id='professional_type-input'
                          options={selectOptions}
                          renderOption={(option, isSelected) => (
                            <SelectOption label={option.label} isSelected={isSelected} />
                          )}
                        />
                        <Button className={'py-1.5 [&_*]:justify-center'} disabled={isRedirectingToProfile} text={isRedirectingToProfile ? <LoadingSpinner /> : 'Search'} />
                      </div>
                      
                    </div>
                  </div>
              )}
              {!locationSearch && (
                <div className="flex sm:flex-row flex-col sm:gap-3 gap-6 sm:text-left justify-center md:justify-start text-center px-2 xs:px-[5.5rem] sm:px-50 md:px-0">
                  <div className="box-border basis-5/6">
                    <label className="text-mint-green text-[0.7rem] font-bold uppercase">
                      Search by Adviser Or Firm Name
                    </label>
                    <div className="flex gap-4 xs:gap-3 sm:flex-row flex-col">
                      <ApiNameSearch className='[&_*]:text-base' onFirstOptionChange={handleFirstSearchByNameOptionChange} loading={isRedirectingToProfile} setLoading={handleSetLoading}/>
                      <Button className={'py-1.5 [&_*]:justify-center'} disabled={isRedirectingToProfile} text={isRedirectingToProfile ? <LoadingSpinner /> : 'Search'} />
                    </div>
                    
                  </div>
                </div>
              )}
            </div>
            <div className="grow-0 w-full basis-full m-0 box-border mt-3 md:text-left text-center">
              <p className="text-white text-[1rem] font-normal leading-[1.43]">
                <span data-test="search-filter-by-location" className={`text-white cursor-pointer mr-4 ${locationSearch ? 'underline font-bold' : ''}`} onClick={() => handleSwitch('location')}>Search by location</span>
                <span data-test="search-filter-by-adviser" className={`text-white cursor-pointer ${!locationSearch ? 'underline font-bold' : ''}`} onClick={() => handleSwitch('name')}>Search by adviser / firm</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}

export default withRouter(HeroImageSection)
