export default function SelectOption({ label, isSelected = false }) {
    return (
        <>
            <div className="flex items-center pr-7">
                <span className={`block truncate text-base font-${isSelected ? 'semibold' : 'normal'}`} aria-label={`Select option ${label}`}>{label}</span>
            </div>

            {isSelected && 
                <span className="absolute inset-y-0 right-0 pr-3 flex items-center text-secondary-blue">
                    <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                    </svg>
                </span>
            }
        </>
    )
}