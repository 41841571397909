import PropTypes from "prop-types";
import Link from "next/link";
import {
  API_KEYS,
  URL_COMPONENTS_NEW as URL_COMPONENTS,
} from "../../__constants__";
import { logoWithVerticalsBlueOnWhite } from "../../assets/images";
import NavItem from "./NavItem";
import MobileMenu from "./MobileMenu";
import Image from "next/future/image";

const Header = ({ disableDropdown, hidden }) => {
  if (hidden) {
    return null;
  }

  return (
    <div
      id="site-header"
      className="h-[45px] py-0 px-4 sm:h-[110px] sm:py-4 relative bg-white z-[999] w-full flex flex-wrap box-border"
    >
      <div className="h-full flex z-[100] items-center md:grow-0 md:max-w-[25%] md:basis-1/4 sm:grow-0 sm:max-w-[16.666667%] sm:basis-1/6 xs:grow-0 xs:max-w-full xs:basis-full m-0 box-border">
        <Link href="/">
          <a href="/">
            <Image
              src={logoWithVerticalsBlueOnWhite}
              width={248}
              height={40}
              className="w-[120px] max-h-5 pl-0 h-auto max-w-full sm:max-w-[90%] sm:w-60 sm:max-h-10 sm:pl-2 md:max-w-full"
              alt="VouchedFor logo"
            />
          </a>
        </Link>
      </div>

      <MobileMenu />

      <div className="relative hidden sm:flex z-[100] items-center justify-end md:grow-0 md:max-w-[75%] md:basis-3/4 m-0 box-border sm:grow-0 sm:max-w-[83.333333%] sm:basis-5/6">
        <NavItem
          label="Financial & Mortgage Advisers"
          role={URL_COMPONENTS.FA_MA_CANONICAL}
          disableDropdown={disableDropdown}
        />
        <NavItem
          label="Solicitors"
          role={URL_COMPONENTS.SOLICITOR}
          disableDropdown={disableDropdown}
        />
        <NavItem
          label="Accountants"
          role={URL_COMPONENTS.ACCOUNTANT}
          disableDropdown={disableDropdown}
          positionDropdownToRight={true}
        />

        <div className="self-center text-center -mt-[0.900rem] ml-4">
          <p className="text-secondary-blue text-[10px] max-[1128px]:text-[9px] font-bold uppercase leading-[1.43] m-0">
            VouchedFor professional?
          </p>
          <div className="flex justify-center">
            <>
              <div className="p-2 pr-5">
                <Link href={`${API_KEYS.ACCOUNTS_URL}/register`}>
                  <a className="no-underline uppercase font-bold hover:underline max-[1128px]:text-[0.9rem] max-[1004px]:text-[0.8rem]" href={`${API_KEYS.ACCOUNTS_URL}/register`}>
                    Register
                  </a>
                </Link>
              </div>

              <div className="p-2">
                <Link href={`${API_KEYS.ACCOUNTS_URL}/login`}>
                  <a className="no-underline uppercase font-bold hover:underline max-[1128px]:text-[0.9rem] max-[1004px]:text-[0.8rem]" href={`${API_KEYS.ACCOUNTS_URL}/login`}>
                    Login
                  </a>
                </Link>
              </div>
            </>
          </div>
        </div>
      </div>
    </div>
  );
};

Header.propTypes = {
  disableDropdown: PropTypes.bool,
  locations: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string.required,
      label: PropTypes.string.required,
    })
  ),
};

export default Header;
