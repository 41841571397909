export default function TextInput({ placeholder, value, onChange, onBlur, iconRight, iconLeft, id, autoFocus = false, disabled = false, onKeyDown = null, autoComplete = true, className, ...rest }) {
    return (
        <div className={`border-0 m-0 inline-flex p-0 relative min-w-0 flex-col w-full`} {...rest}>
            <div className={`pr-3 py-0.5 pl-1 relative inline-flex rounded-full cursor-text text-base box-border items-center font-normal bg-white ring-1 ring-inset ring-gray-300 focus-within:outline-none focus-within:ring-2 focus-within:ring-secondary-blue ${className}`}>
                {iconLeft &&
                    <div className="ml-2 flex items-center whitespace-nowrap">
                        {iconLeft}
                    </div>
                }
                
                <input
                    type="text"
                    id={id}
                    name={id}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    placeholder={placeholder}
                    autoFocus={autoFocus}
                    disabled={disabled}
                    onKeyDown={onKeyDown}
                    autoComplete={autoComplete ? 'on' : 'off'}
                    data-test={`text-input-${id}`}
                    className={`pr-0 py-[10px] focus-within:outline-none outline-none pl-${iconLeft ? '2' : '3' } ${disabled ? 'cursor-not-allowed' : ''} w-full border-0 rounded-full m-0 block min-w-0 box-content`}
                />

                {iconRight &&
                    <div className="ml-2 flex items-center whitespace-nowrap">
                        {iconRight}
                    </div>
                }
            </div>
        </div>
    )
}